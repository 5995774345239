import React from "react"
import {RecoilRoot} from "recoil"
import { ParallaxProvider } from 'react-scroll-parallax';

import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
  return (
      <RecoilRoot>
        <ParallaxProvider>
        {element}
        </ParallaxProvider>
      </RecoilRoot>
  )
}